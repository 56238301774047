<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        hide-default-footer
        disable-pagination
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\quizdictionaries\\list.910') }}</v-toolbar-title>
                <v-spacer />
                <v-btn v-if="user.adminAccess && user.adminAccess.quiz_dictionaries >= ACCESS.WRITE" color="primary" @click="create">
                    <v-icon>mdi-plus</v-icon>{{ $t('src\\views\\quizdictionaries\\list.1624') }}</v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ACCESS } from '@/vars';

    export default {
        name: 'DictionariesList',
        components: {
        },
        data: () => ({
            ACCESS,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('quizDictionaries', ['entities']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            await store.dispatch('quizDictionaries/fetch', {});
            this.headers = [
                { text: this.$t('src\\views\\quizdictionaries\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\quizdictionaries\\list.code'), value: 'code' },
                { text: this.$t('src\\views\\quizdictionaries\\list.name'), value: 'name' },
            ]
            if(this.user && this.user.adminAccess && this.user.adminAccess.quiz_dictionaries >= ACCESS.WRITE) {
                this.headers.push({ text: '', align: 'right', value: 'edit' })
            }
        },
        methods: {
            async create() {
                await this.$router.push({ name: 'quiz-dictionaries-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'quiz-dictionaries-edit', params: { id } });
            }
        }
    }
</script>
